
import './App.css';
import Index from './route';

function App() {
  return (
    <>
    <Index />
    </>

  );
}

export default App;
